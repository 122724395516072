/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shape': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<defs><linearGradient id="svgicon_shape_a" x1="1.377" y1=".209" x2="23.212" y2="24.151" gradientUnits="userSpaceOnUse"><stop stop-color="#B65DFC"/><stop offset="1" stop-color="#5863FC"/></linearGradient></defs><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M28.1 1.381C25.775.472 21.601.036 17.5 0 13.398.036 9.224.472 6.937 1.345 2.514 3.063 1.182 7.667.977 8.488l-.004.015-.008.036-.047.202c-.703 3.011-.95 6.053-.914 8.74-.036 2.709.173 5.731.9 8.726l.077.317c.007.03 0 .005.008.035l.001.003c.243.943 1.607 5.407 5.947 7.093 2.287.872 6.461 1.308 10.563 1.345 4.102-.037 8.276-.437 10.563-1.345 4.423-1.718 5.754-6.321 5.96-7.143l.003-.015.009-.036.047-.203c.703-3.01.95-6.052.914-8.74.036-2.72-.175-5.756-.874-8.728l-.053-.224-.009-.037-.004-.018c-.216-.855-1.556-5.42-5.957-7.13z" _fill="url(#svgicon_shape_a)"/>'
  }
})
