/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10.9 1.354a.5.5 0 10-.708-.708l.707.708zM.646 10.192a.5.5 0 10.708.707l-.708-.707zm9.546.707a.5.5 0 10.707-.707l-.707.707zM1.354.646a.5.5 0 10-.708.708l.708-.708zm8.838 0L5.42 5.42l.708.708 4.772-4.773-.707-.708zM5.42 5.42L.646 10.192l.708.707 4.773-4.772-.708-.708zm5.48 4.773L6.127 5.42l-.708.708 4.773 4.772.707-.707zM6.127 5.42L1.354.646l-.708.708L5.42 6.127l.708-.708z" _fill="#000" fill-opacity=".4"/>'
  }
})
