/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<rect pid="0" x="5" width="2" height="12" rx="1" _fill="#07F"/><rect pid="1" x="12" y="5" width="2" height="12" rx="1" transform="rotate(90 12 5)" _fill="#07F"/>'
  }
})
