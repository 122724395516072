/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.247 16.128l-7.5-15c-.283-.565-1.209-.565-1.491 0l-7.5 15a.832.832 0 00.745 1.206h15a.836.836 0 00.746-1.206zm-9.08-8.795h1.667v5H8.167v-5zm.834 7.709a1.041 1.041 0 110-2.083 1.041 1.041 0 010 2.083z" _fill="#EB5757"/>'
  }
})
