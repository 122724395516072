/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reject': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.039 18.92L2.146 20.81l.708.708 2.353-2.354H19a2 2 0 002-2v-2a2 2 0 00-2-2h-7.793l2-2H19a2 2 0 002-2v-2a2 2 0 00-1.802-1.99l1.656-1.656-.707-.707-2.354 2.353H5a2 2 0 00-2 2v2a2 2 0 002 2h6.793l-2 2H5a2 2 0 00-2 2v2a2 2 0 001.039 1.754zm2.168-.755H19a1 1 0 001-1v-2a1 1 0 00-1-1h-8.793l-4 4zm2.586-4l-3.982 3.982A1 1 0 014 17.165v-2a1 1 0 011-1h3.793zm5.414-4H19a1 1 0 001-1v-2a1 1 0 00-1-1h-.793l-4 4zm2.586-4l-4 4H5a1 1 0 01-1-1v-2a1 1 0 011-1h11.793z" _fill="#000"/>'
  }
})
