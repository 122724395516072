/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'draggable': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="3" cy="6" r="1" _fill="#B4BAC6"/><circle pid="1" cx="12" cy="6" r="1" _fill="#B4BAC6"/><circle pid="2" cx="21" cy="6" r="1" _fill="#B4BAC6"/><circle pid="3" cx="3" cy="12" r="1" _fill="#B4BAC6"/><circle pid="4" cx="12" cy="12" r="1" _fill="#B4BAC6"/><circle pid="5" cx="21" cy="12" r="1" _fill="#B4BAC6"/><circle pid="6" cx="3" cy="18" r="1" _fill="#B4BAC6"/><circle pid="7" cx="12" cy="18" r="1" _fill="#B4BAC6"/><circle pid="8" cx="21" cy="18" r="1" _fill="#B4BAC6"/>'
  }
})
