/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logo': {
    width: 150,
    height: 21,
    viewBox: '0 0 150 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.44 6.972c0 3.872-3.087 7.03-6.883 7.03H2.836V12.51h5.721c2.976 0 5.395-2.486 5.395-5.54 0-3.055-2.42-5.541-5.395-5.541h-7.07v19.51H0V0h9.443c3.378.447 5.997 3.402 5.997 6.972zm4.484 12.525H33.83v1.49H18.482v-.048h-.046V0h1.488v19.497zM55.144 21L46.89 0h-1.438l-8.417 21h1.568l7.559-18.404L53.579 21h1.564zm14.27-21l-6.981 11.134v9.805h-1.488v-9.526L53.826 0h1.753l6.024 9.656L67.658 0h1.755zm3.584 0h1.487v20.939h-1.487V0zm14.846 0v9.72H76.132v1.489h11.712v9.73h1.487V0h-1.487zm5.674 20.939V0h15.394v1.431H95.006V9.72h13.906v1.489H95.006v8.288h13.906v1.49H93.564v-.048h-.046zM117.38 14l11.224 7h-2.814l-11.18-6.97.016-.028h-.041V12.51h7.813c2.976 0 5.395-2.486 5.395-5.54 0-3.055-2.421-5.541-5.395-5.541h-9.201V0h10.094c3.379.447 5.997 3.402 5.997 6.972 0 3.872-3.087 7.03-6.882 7.03L117.381 14zm31.055 7H150l-8.253-21h-1.438l-8.416 21h1.568l7.559-18.404L148.436 21z" _fill="#000"/>'
  }
})
