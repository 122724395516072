/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dropdown-chevron': {
    width: 13,
    height: 7,
    viewBox: '0 0 13 7',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.794 2.095L6.751 6.736A1.04 1.04 0 016.045 7a.995.995 0 01-.699-.264L.295 2.096A.85.85 0 010 1.445C0 1.19.098.976.295.805L.877.27A.963.963 0 011.575 0c.28 0 .512.09.699.27l3.77 3.458L9.817.27a.964.964 0 01.698-.27c.274 0 .51.09.706.27l.582.535a.84.84 0 01.287.642.85.85 0 01-.295.648z" _fill="#DCE0E6"/>'
  }
})
