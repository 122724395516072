/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'draft': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M19.7 1.688L18.616.605c-.806-.806-2.211-.806-3.017 0l-1.402 1.401-.004.005-.004.003-8.347 8.347a.597.597 0 00-.169.338l-.542 3.794a.6.6 0 00.678.68l3.794-.541a.6.6 0 00.339-.17l8.346-8.347.004-.003.004-.005 1.402-1.402c.403-.402.625-.938.625-1.508 0-.57-.221-1.106-.623-1.509zM9.236 13.473l-2.804.4.401-2.804 7.785-7.784 2.403 2.402-7.785 7.786zm9.615-9.615l-.982.981-2.403-2.402.982-.982a.952.952 0 011.319 0l1.084 1.084a.924.924 0 01.273.66.922.922 0 01-.273.659zm.349 6.722v5c0 2.537-2.063 4.6-4.6 4.6h-10a4.605 4.605 0 01-4.6-4.6v-10C0 3.043 2.063.98 4.6.98h5a.6.6 0 110 1.2h-5a3.404 3.404 0 00-3.4 3.4v10c0 1.875 1.525 3.4 3.4 3.4h10c1.875 0 3.4-1.524 3.4-3.4v-5a.6.6 0 111.2 0z" _fill="#000"/>'
  }
})
