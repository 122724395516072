/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'circle-outline': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 19a8 8 0 100-16 8 8 0 000 16zm11-8c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z" _fill="#DEDEDE"/>'
  }
})
