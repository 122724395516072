/* eslint-disable */
import './add'
import './alert'
import './ambassador'
import './attachment'
import './back'
import './bracket'
import './cancel'
import './chat-2'
import './chat'
import './checked'
import './circle-outline'
import './circle'
import './close'
import './done'
import './dots'
import './down-arrow'
import './draft'
import './drag-n-drop'
import './draggable-choice'
import './draggable'
import './dropdown-chevron'
import './edit-results'
import './edit'
import './enter'
import './info'
import './link'
import './logo'
import './menu'
import './message-outline'
import './message'
import './move-in'
import './move-out'
import './pick-for-move'
import './pin'
import './play-2'
import './play'
import './reject'
import './reload'
import './search'
import './shape-mask-primary'
import './shape-mask'
import './shape'
import './sms'
import './special-event'
import './stop'
import './translate'
import './trash'
import './up-arrow'
import './verified-pink'
import './verified'
