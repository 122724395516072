/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shape-mask-primary': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.268.947C17.675.324 14.813.025 12 0 9.187.025 6.325.324 4.757.922 1.42 2.218.65 5.906.65 5.906.152 8-.022 10.118.003 11.987-.022 13.882.127 16 .65 18.093c0 0 .771 3.689 4.107 4.985 1.568.598 4.43.897 7.243.922 2.813-.025 5.675-.3 7.243-.922 3.336-1.296 4.107-4.985 4.107-4.985.498-2.093.672-4.211.647-6.08.025-1.895-.124-4.013-.622-6.082 0 0-.772-3.688-4.107-4.984z" _fill="#07F"/>'
  }
})
