/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'draggable-choice': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M21.55 11.23a.6.6 0 01-.13.2l-2.88 2.87a.601.601 0 01-.85-.85l1.86-1.86h-8v8l1.86-1.86a.6.6 0 11.85.85l-2.88 2.88a.6.6 0 01-.85 0L7.7 18.54a.602.602 0 01.655-.981.6.6 0 01.195.13l1.86 1.86v-8h-8l1.89 1.91a.6.6 0 11-.85.85L.58 11.42a.6.6 0 010-.85L3.46 7.7a.601.601 0 01.85.85l-1.86 1.85h8v-8l-1.91 1.9a.601.601 0 11-.85-.85L10.57.57a.6.6 0 01.85 0l2.88 2.89a.601.601 0 11-.85.85l-1.85-1.86v8h8l-1.9-1.91a.601.601 0 01.85-.85l2.88 2.88a.6.6 0 01.13.65l-.01.01z" _fill="#9FB0C9"/>'
  }
})
