/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cancel': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm0 16.981c-4.4 0-7.981-3.58-7.981-7.981 0-4.4 3.58-7.981 7.981-7.981 4.4 0 7.981 3.58 7.981 7.981 0 4.4-3.58 7.981-7.981 7.981zM11.762 6.96L9.72 9l2.042 2.041a.51.51 0 01-.72.72L9 9.72l-2.041 2.041a.509.509 0 11-.72-.72L8.28 9 6.238 6.959a.51.51 0 11.72-.72L9 8.28l2.041-2.041a.51.51 0 11.72.72z" _fill="#B4BAC6"/>'
  }
})
