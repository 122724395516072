/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'verified': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M5.113 11.32c.236.318.502.68.887.68s.652-.362.886-.68l.002-.004c.137-.184.305-.413.464-.448.141 0 .357.094.566.184.38.166.8.308 1.082.145.327-.189.375-.611.42-1.02.026-.234.059-.523.146-.61.086-.087.375-.12.608-.146h.002c.409-.046.832-.094 1.02-.42.187-.322.019-.708-.144-1.083-.095-.217-.212-.488-.18-.612.03-.112.261-.282.446-.419.32-.235.682-.502.682-.887s-.362-.652-.681-.887c-.186-.137-.416-.307-.446-.42-.033-.123.085-.394.18-.611.162-.374.33-.76.143-1.083-.188-.327-.61-.374-1.02-.42-.233-.026-.523-.059-.61-.146-.086-.086-.119-.376-.145-.609-.046-.41-.093-.832-.42-1.02-.284-.165-.704-.021-1.083.143-.209.091-.427.178-.612.18-.112-.03-.28-.257-.416-.442L6.887.681C6.65.36 6.385 0 6 0c-.384 0-.651.362-.887.68l-.002.003c-.136.185-.305.414-.464.45-.14 0-.355-.094-.564-.185h-.001c-.38-.165-.8-.308-1.083-.145-.327.19-.374.612-.42 1.02-.026.234-.058.523-.145.61-.087.087-.376.12-.608.146h-.002c-.409.046-.832.094-1.02.42-.187.322-.02.708.143 1.082.095.218.213.49.18.613-.03.112-.26.282-.445.419C.362 5.348 0 5.615 0 6s.362.652.681.887c.186.137.416.307.446.42.033.123-.085.394-.18.611-.162.374-.33.76-.144 1.083.189.327.612.374 1.02.42h.001c.233.026.523.059.61.146.086.086.119.376.145.609.046.41.093.832.42 1.02.283.165.703.021 1.083-.143.209-.091.423-.183.612-.18.112.03.28.257.416.442l.003.004z" _fill="#3389FF"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9a.391.391 0 01-.266-.104L3.109 7.11A.35.35 0 013 6.858a.35.35 0 01.109-.253.376.376 0 01.265-.105.392.392 0 01.266.105L5.21 8.1l3.115-3.957a.368.368 0 01.247-.14.394.394 0 01.278.068.346.346 0 01.076.5L5.55 8.857a.389.389 0 01-.3.143z" _fill="#fff"/>'
  }
})
