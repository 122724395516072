/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.663 6.857L17.17.337a1.143 1.143 0 00-1.92 1.069l.315 1.371L8.709 8.03l-3.726.411a1.714 1.714 0 00-1.023 2.92l3.034 3.029-6.863 8.68a.571.571 0 00.8.8l8.68-6.857 3.029 3.034a1.715 1.715 0 002.92-1.023l.44-3.72 5.251-6.857 1.372.314a1.143 1.143 0 001.04-1.903z" _fill="#DCE0E6"/>'
  }
})
