/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'back': {
    width: 20,
    height: 14,
    viewBox: '0 0 20 14',
    data: '<path pid="0" d="M.18 7.44l6.19 6.18a.62.62 0 00.87-.87L2.11 7.62h17.27a.62.62 0 000-1.24H2.12l5.12-5.12a.62.62 0 10-.87-.87L.18 6.58a.62.62 0 000 .86z" _fill="#fff"/>'
  }
})
