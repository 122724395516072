/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ambassador': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g filter="url(#filter0_d)"><mask id="svgicon_ambassador_a" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18"><path pid="0" d="M17.25 3H6.75C5.785 3 5 3.808 5 4.8v10.8c0 .29.136.563.367.733l6.125 4.5a.86.86 0 001.016 0l6.125-4.5A.91.91 0 0019 15.6V4.8c0-.993-.784-1.8-1.75-1.8zm-2.897 11.762a.3.3 0 01-.455.34l-1.728-1.186a.3.3 0 00-.34 0l-1.728 1.186a.3.3 0 01-.455-.34l.547-1.69a.3.3 0 00-.07-.302l-1.129-1.16a.3.3 0 01.215-.51h1.697a.3.3 0 00.285-.207l.523-1.612a.3.3 0 01.57 0l.523 1.611a.3.3 0 00.285.208h1.697a.3.3 0 01.215.51l-1.129 1.16a.3.3 0 00-.07.302l.547 1.69zM17.25 6.3a.3.3 0 01-.3.3h-9.9a.3.3 0 01-.3-.3V5.1a.3.3 0 01.3-.3h9.9a.3.3 0 01.3.3v1.2z" _fill="url(#paint0_linear)"/></mask><g mask="url(#svgicon_ambassador_a)"><path pid="1" d="M5 3h14v18H5V3z" _fill="url(#paint1_linear)"/><path pid="2" _fill="url(#paint2_linear)" fill-opacity=".3" d="M3-1h9v25H3z"/></g></g><defs><linearGradient id="paint0_linear" x1="5" y1="3" x2="19" y2="18.5" gradientUnits="userSpaceOnUse"><stop stop-color="#F1C736"/><stop offset="1" stop-color="#E24343"/></linearGradient><linearGradient id="paint1_linear" x1="5" y1="3" x2="19" y2="18.5" gradientUnits="userSpaceOnUse"><stop stop-color="#F1C736"/><stop offset="1" stop-color="#E24343"/></linearGradient><linearGradient id="paint2_linear" x1="7.5" y1="1.5" x2="7.5" y2="21" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><filter id="filter0_d" x="0" y="0" width="24" height="24.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy=".5"/><feColorMatrix values="0 0 0 0 0.593886 0 0 0 0 0.249092 0 0 0 0 0.184131 0 0 0 1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs>'
  }
})
