/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'special-event': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: '<g clip-path="url(#clip0_51243_210501)"><path pid="0" d="M23.688 6.92L19.898.964a2.068 2.068 0 00-.754-.705A2.108 2.108 0 0018.138 0H5.862C5.51 0 5.16.087 4.851.256a2.07 2.07 0 00-.759.708L.312 6.92A2.026 2.026 0 00.5 9.35L11.204 21.65a1.04 1.04 0 00.791.36 1.054 1.054 0 00.792-.36L23.49 9.35a2.025 2.025 0 00.198-2.43zM12.662 19.222a1.048 1.048 0 01-.765.228 1.052 1.052 0 01-.704-.372L6.56 13.512a1.016 1.016 0 01.146-1.446 1.054 1.054 0 011.468.144l4.633 5.587c.087.104.153.224.192.353.04.13.054.265.04.4a1.013 1.013 0 01-.377.692v-.02z" _fill="#929292"/></g><defs><clipPath id="clip0_51243_210501"><path pid="1" _fill="#fff" d="M0 0h24v22H0z"/></clipPath></defs>'
  }
})
