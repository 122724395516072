/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sms': {
    width: 51,
    height: 52,
    viewBox: '0 0 51 52',
    data: '<path pid="0" d="M34.37 0H16.63c-4.28 0-7.76 3.402-7.76 7.583v36.834c0 4.181 3.48 7.583 7.76 7.583h17.74c4.28 0 7.76-3.402 7.76-7.583V7.583C42.13 3.402 38.65 0 34.37 0zm5.543 44.417c0 2.985-2.486 5.416-5.543 5.416H16.63c-3.057 0-5.543-2.43-5.543-5.416v-3.25h28.826v3.25zm0-5.417H11.087V10.833h28.826V39zm0-30.333H11.087V7.583c0-2.985 2.486-5.416 5.543-5.416h17.74c3.057 0 5.543 2.43 5.543 5.416v1.084zm-14.413 39c1.224 0 2.217-.971 2.217-2.167 0-1.196-.993-2.167-2.217-2.167-1.224 0-2.217.971-2.217 2.167 0 1.196.99 2.167 2.217 2.167zM6.927 17.727c-.587-.186-1.21.118-1.403.688a20.138 20.138 0 00-1.09 6.504c0 2.192.367 4.383 1.09 6.504a1.11 1.11 0 001.053.741c.117 0 .233-.02.35-.054.579-.188.894-.802.7-1.37a18.04 18.04 0 01-.975-5.821c0-1.961.328-3.92.976-5.822a1.08 1.08 0 00-.7-1.37zm-4.202-1.37a1.118 1.118 0 00-1.403.684A24.274 24.274 0 000 24.917c0 2.66.443 5.31 1.32 7.875a1.111 1.111 0 001.405.68c.579-.188.894-.8.7-1.369a22.218 22.218 0 01-1.208-7.189c0-2.424.408-4.842 1.209-7.189a1.078 1.078 0 00-.7-1.369zM34.414 4.332h-.022c-.612 0-1.098.486-1.098 1.084 0 .598.51 1.083 1.12 1.083.612 0 1.109-.485 1.109-1.083s-.497-1.084-1.11-1.084zm9.657 13.395a1.078 1.078 0 00-.701 1.369 18.04 18.04 0 01.976 5.822c0 1.96-.329 3.92-.976 5.822-.193.565.12 1.18.7 1.369.118.037.233.054.351.054.466 0 .896-.288 1.053-.74a20.14 20.14 0 001.089-6.505 20.14 20.14 0 00-1.089-6.505c-.193-.567-.816-.873-1.403-.686zm5.607-.683a1.118 1.118 0 00-1.403-.685 1.079 1.079 0 00-.7 1.37 22.218 22.218 0 011.208 7.189c0 2.424-.408 4.842-1.209 7.189-.193.565.12 1.18.7 1.37.118.038.234.055.351.055.464 0 .896-.288 1.053-.736A24.273 24.273 0 0051 24.92c0-2.66-.444-5.31-1.322-7.876zM27.718 4.333h-4.435c-.612 0-1.11.486-1.11 1.084 0 .598.498 1.083 1.11 1.083h4.434c.612 0 1.11-.485 1.11-1.083s-.498-1.084-1.11-1.084z" _fill="#D3DBE5"/>'
  }
})
