/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'circle': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25z" _fill="#07F"/>'
  }
})
