/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0a8.001 8.001 0 000 16A8.001 8.001 0 008 0zm0 3.548a1.355 1.355 0 110 2.71 1.355 1.355 0 010-2.71zm1.806 8.194a.387.387 0 01-.387.387H6.581a.387.387 0 01-.387-.387v-.774c0-.214.173-.387.387-.387h.387V8.516H6.58a.387.387 0 01-.387-.387v-.774c0-.214.173-.387.387-.387h2.064c.214 0 .387.173.387.387v3.226h.387c.214 0 .387.173.387.387v.774z" _fill="#B4BAC6"/>'
  }
})
