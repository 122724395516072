/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'done': {
    width: 12,
    height: 9,
    viewBox: '0 0 12 9',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.783 1.242L10.73.212A.725.725 0 0010.204 0a.725.725 0 00-.527.212L4.6 5.19 2.323 2.955a.725.725 0 00-.527-.213.725.725 0 00-.526.213L.217 3.985A.694.694 0 000 4.5c0 .202.072.374.217.515l2.802 2.743 1.053 1.03c.145.141.32.212.527.212.206 0 .382-.07.526-.212l1.053-1.03 5.605-5.485A.694.694 0 0012 1.758a.694.694 0 00-.217-.516z" _fill="#fff"/>'
  }
})
