/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'attachment': {
    width: 18,
    height: 22,
    viewBox: '0 0 18 22',
    data: '<path pid="0" d="M16.438 4.86l-4.1-4.1A2.582 2.582 0 0010.5 0H3.6A3.6 3.6 0 000 3.6v14a3.6 3.6 0 003.6 3.6h10a3.6 3.6 0 003.6-3.6V6.7a2.582 2.582 0 00-.762-1.84zM16 17.6a2.4 2.4 0 01-2.4 2.4h-10a2.4 2.4 0 01-2.4-2.4v-14a2.4 2.4 0 012.4-2.4h6.9c.371.001.727.148.99.41l4.1 4.1a1.401 1.401 0 01.41.99v10.9zm-3.966-9.95a2.968 2.968 0 010 4.2L8.27 15.611a1.717 1.717 0 01-1.223.507 1.73 1.73 0 01-1.222-2.952l3.582-3.583a.6.6 0 11.848.849l-3.582 3.582a.532.532 0 000 .75.532.532 0 00.749 0L11.185 11a1.768 1.768 0 000-2.5 1.813 1.813 0 00-2.5 0l-3.327 3.323a.6.6 0 11-.848-.848L7.834 7.65a3.041 3.041 0 014.2 0z" _fill="#9DA4C0"/>'
  }
})
